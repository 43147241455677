

























































































































import { Empresa } from '@/core/models/geral';
import { RelatorioMedicao } from '@/core/models/operacional';
import { PageBase } from '@/core/models/shared';
import { EmpresaService } from '@/core/services/geral';
import { RelatorioMedicaoService } from '@/core/services/operacional';
import { AlertQuestion, AlertSimpleErr } from '@/core/services/shared/AlertService';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';


@Component
export default class DetalharRegistroOperacional extends PageBase { 

    service = new RelatorioMedicaoService();
    item: RelatorioMedicao = new RelatorioMedicao();

    empresaService = new EmpresaService();
    empresa: Empresa = new Empresa();
    overlay: boolean = false;
    id: number = 0;
    selectRadio: number = 0;

    mounted() {
        this.id = Number(this.$route.params.id);
        this.Carregar();
    }

    Carregar(){
        this.overlay = true;

        this.service.ObterPorId(this.id, "RegistroOperacional.Proposta.Cliente, Itens.RegistroOperacionalEquipamento.Equipamento.TipoServicoEquipamento, Aprovador")
            .then(
                res=> {
                    this.item = res.data;
                    this.item.itens = this.item.itens.sort(function(a,b){
                        if(a.registroOperacionalEquipamento.equipamento.nome < b.registroOperacionalEquipamento.equipamento.nome){
                            return -1;
                        }
                        if(a.registroOperacionalEquipamento.equipamento.nome > b.registroOperacionalEquipamento.equipamento.nome){
                            return 1;
                        }
                        return 0;
                    });
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() =>{
                this.overlay = false;
            });
        
        this.empresaService.ObterPorId(this.app.empresaId, "Endereco.Municipio.Estado").then(
            res=>{
                this.empresa = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Aprovar(){
        const context = this;
        const aprovar = function () {
            return new Promise( async function (resolve, reject){
                context.service.Aprovar(context.item.id, context.app.usuarioId).then(
                    res => {
                        return res.data;
                    },
                    err => AlertSimpleErr("Aviso!", err)
                )
            });
        }
        AlertQuestion("Atenção!", "Tem certeza que deseja APROVAR o registro atual?", aprovar);                
    }
}
